import {post, get} from "../Api";
import {setTokenSession} from "./UseSessionStorage";

export const login = async (data: object) => {
  return await post('/auth/login', data).then(function (token) {
    setTokenSession(token);
    return token;
  }).catch(function (e) {
    throw new Error('Usuario o contraseña invalido');
  });
}

export const getUserLoggedIn = async () => {
  return await get('/users');
}