import axios, { AxiosRequestConfig } from 'axios';

const API_URL = 'http://localhost:3003';

axios.defaults.baseURL = API_URL;

axios.defaults.withCredentials = true;

interface RequestData {
  [key: string]: any;
}

export const get = async (path: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.get(`${API_URL}${path}`, config);

  return response.data;
};

export const post = async (path: string, data: RequestData): Promise<any> => {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.post(`${API_URL}${path}`, data, config);

  return response.data;
};

export const put = async (path: string, data: RequestData): Promise<any> => {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.put(`${API_URL}${path}`, data, config);

  return response.data;
};

export const del = async (path: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.delete(`${API_URL}${path}`, config);

  return response.data;
};