export const setUserSession = async (user: any) => {
  window.sessionStorage.setItem('user', user);
}

export const getUserSession = async () => {
  return window.sessionStorage.getItem('user');
}

export const removeUserSession = async () => {
  window.sessionStorage.removeItem('user');
}

export const setTokenSession = async (token: string) => {
  window.sessionStorage.setItem('token', token);
}

export const getTokenSession = async () => {
  return window.sessionStorage.getItem('token');
}

export const removeTokenSession = async () => {
  window.sessionStorage.removeItem('token');
}
